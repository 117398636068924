/*******************
 * 1. UNIVERSALS
 *******************/
/*******************
 * 2. GRADIA
 *******************/
.gradia header, .gradia #recapToggle, .gradia button, .gradia #recapNav {
  background: url(../img/3859499_citrus.jpg), #ca4a00;
  background-size: cover;
}
.gradia .portrait {
  border: 3px ridge #ff6e6e;
}

/*******************
 * 2. BSBS
 *******************/
.bsbs header, .bsbs #recapToggle, .bsbs button, .bsbs #recapNav {
  background: url(../img/3859499_darkblue.jpg), #181775;
  background-size: cover;
}
.bsbs .portrait {
  border: 3px ridge #33328b;
}

@media (min-width: 950px) {
  .App.bsbs {
    background-image: url("../img/1974.387_Hampstead_Stormy_Sky.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .App.gradia {
    background-image: url("../img/orysca-map.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .bsbs #info {
    background: url(../img/3859499_darkblue.jpg), #181775;
    background-size: cover;
  }

  .gradia #info {
    background: url(../img/3859499_citrus.jpg), #ca4a00;
    background-size: cover;
  }
}
@media screen and (max-width: 1105px) {
  .grid {
    grid-template-columns: repeat(3, 125px);
  }
}
@media screen and (min-width: 1105px) {
  .grid {
    grid-template-columns: repeat(4, 125px);
    grid-auto-rows: minmax(125px);
  }
}
@media screen and (max-width: 950px) {
  .grid {
    grid-template-columns: repeat(3, 100px);
    grid-auto-rows: minmax(100px);
  }
}
@media screen and (min-width: 950px) {
  .App .container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    position: relative;
  }

  main h1 {
    margin-top: 0;
    padding-top: 20px;
  }

  main {
    border-left: 5px double white;
    border-right: 5px double white;
    border-bottom: 5px double white;
    margin: 0;
    max-height: 100%;
  }

  main, header, aside, #recap {
    box-shadow: 0px 5px 8px black;
  }

  .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  footer {
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }

  footer a {
    color: white;
  }

  #info, #recapNav {
    position: fixed;
    width: 20%;
    /* background-image: linear-gradient(
        to right, rgba(0,0,0,0.7) 90%, rgba(0, 0, 0, 0)); */
    height: max-content;
    color: white;
    border: 5px double white;
    background-size: cover;
  }

  #info {
    top: 10px;
    left: 10px;
    max-height: 90%;
    height: unset;
    overflow-y: scroll;
  }

  #recapNav {
    display: unset;
    padding: 10px;
    top: 10px;
    right: 10px;
  }
  #recapNav ul {
    list-style: none;
  }
  #recapNav a {
    text-decoration: none;
    color: white;
  }

  .infoPortrait {
    max-width: 100%;
    max-height: 100%;
  }

  .gridContainer {
    max-height: 100%;
    overflow: visible;
  }

  .portrait {
    height: 125px;
  }

  .tint {
    position: absolute;
    width: 125px;
    height: 125px;
  }

  .portrait-content img {
    height: 125px;
    width: 125px;
  }

  .toggleContainer {
    margin-bottom: 0;
  }

  #recapToggle {
    right: -120px;
    z-index: -1;
  }

  #recap {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 50%;
    height: auto;
    top: 10px;
    bottom: 10px;
    left: auto;
    right: auto;
    background-color: none;
    border: none;
    padding: 0;
  }

  #closeInfo {
    position: fixed;
    width: initial;
    top: 0;
    left: calc(20% + 15px);
  }
  #closeInfo ::after {
    content: "x";
  }
  #closeInfo h2 {
    margin: 0;
  }
}

