/*******************
 * 1. UNIVERSALS
 *******************/

//colors
$body: #fdfdfd; //#cccccc;
$tintColor: rgb(0,0,0);

//borders
$componentBorder: 5px double white;

/*******************
 * 2. GRADIA
 *******************/
 
 //colors
$gradia-header: #ca4a00; //#69140E;
$gradia-gridPortrait: #ff6e6e; //#00a44a;
$gradia-infoBorder: rgb(71,46,0);

//imgs
$gradia-headerImg: url(../img/3859499_citrus.jpg); //url(../img/3859499.jpg);
$gradia-backgroundImg: url("../img/orysca-map.png");

.gradia {
    header, #recapToggle, button, #recapNav{
        background: $gradia-headerImg, $gradia-header;
        background-size: cover;
    }
    .portrait {
        border: 3px ridge $gradia-gridPortrait;
    }
 }

/*******************
 * 2. BSBS
 *******************/
 
 //colors
 $bsbs-header: #181775; //#69140E;
 $bsbs-gridPortrait: hsl(241, 47%, 37%); //#00a44a;
 $bsbs-infoBorder: rgb(71,46,0);
 
 //imgs
 $bsbs-headerImg: url(../img/3859499_darkblue.jpg); //url(../img/3859499.jpg);
 $bsbs-backgroundImg: url("../img/1974.387_Hampstead_Stormy_Sky.jpg");

 .bsbs {
    header, #recapToggle, button, #recapNav{
        background: $bsbs-headerImg, $bsbs-header;
        background-size: cover;
    }
    .portrait {
        border: 3px ridge $bsbs-gridPortrait;
    }
 }

 @media (min-width:950px){
    .App.bsbs {
            background-image: $bsbs-backgroundImg;
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
        
    }
    .App.gradia {
        
            background-image: $gradia-backgroundImg;
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
        
    }
    .bsbs #info {
        background: $bsbs-headerImg, $bsbs-header;
        background-size: cover;
    }
    .gradia #info {
        background: $gradia-headerImg, $gradia-header;
        background-size: cover;
    }
}