@use "sass:color";
@import "vars";

* {
  box-sizing:border-box;
}

body {
  margin: 0;
}

.App {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    box-sizing: border-box;
}

.App .container {
  margin: 0;
  background-color: $body;
  z-index: 0;
}

h1, h2, h3 {
  font-family: 'Castellar', 'Times New Roman', serif;
  text-transform: uppercase;
}

h4 {
  text-transform: uppercase;
}

header {
  text-align: center;
  background-size:cover;
  color:whitesmoke;
  border:$componentBorder;
  padding: {
    left: 10px;
    right: 10px;
  }
  
}

.gridContainer {
  max-height:70vw;
  overflow-y:scroll;
  padding-bottom:15px;
}

.grid{
  display:grid;
  gap: 10px;
  grid-template-columns: repeat(3, 100px);
  grid-auto-rows:minmax(100px);
  width:min-content;
  margin-left:auto;
  margin-right:auto;
  
}

.portrait {
  box-shadow: 0px 5px 10px;
  overflow: hidden;
  position:relative;
  padding:0;
  height:100px; 
}

.tint{
  position:absolute;
  width:100px;
  height:100px;
  opacity:0.5;
  transition: opacity 0.5s;
  background-color: $tintColor;
}

.portrait-content img {
  height:100px;
  width:100px;
  opacity:1;
}

.tint:hover{
  opacity:0;
}

main {
  text-align:center;
  padding:10px;
  background-color: $body;
}

#info {
  height:45%;
  padding: 10px;
  color:black;
}

.toggleContainer {
  position:relative;
  margin-bottom: 20px;
}

#recapNav {
  display:none;
}

#recapToggle {
  border: $componentBorder;
  position:absolute;
  top:0px;
  right:0px;
  width:40%;
  background-size:cover;
  color:white;
  padding: 0 10px 0 10px;
  text-align: right;
}

#recap {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow:scroll;
  word-wrap: break-word;
  border: none;
  z-index: 4;
  background-color: none;
}

.recap-enter {
  opacity:0;
  transform: scale(0.9);
}

.recap-enter-active {
  opacity:1;
  transform: translateX(0);
  transition: opacity 400ms, transform 300ms;
}

.recap-exit {
  opacity: 1;
}

.recap-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

#closeRecap {
  // position:absolute;
  // right:0;
  // top: 0;
  // color:white;
  // width:30px;
  // height:30px;
  // border:$componentBorder;
  // margin:0;
  display:inline;
}

aside * {
  margin: 0;
}

.infoPortrait{
  display:block;
  margin: 10px auto 0 auto;
}

#infoAppearances {
  margin-top: 15px;
}

footer {
  padding: 10px;
  text-align: center;
  margin-top: 10px;
}

button{
  font-family: inherit;
  border:0;
  height:auto;
  width:50%;
  background-size:cover;
  color:white;
  display:block;
  margin: 10px auto 10px auto;
  border: $componentBorder;
}

#recap p {
  text-align: left;
}

#recap .stinger {
  text-indent: 0;
  text-align: justify;
  padding-left: 15px;
  padding-right: 15px;
}

footer a {
  text-decoration: underline;
}

.portraitAnim-enter {
  opacity: 0;
}

.portraitAnim-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

#recap header {
  position:sticky;
  top:0;
}

.recapHeader {
  scroll-margin-top: 120px;
}

.sandwich {
  border-left:$componentBorder;
  border-right: $componentBorder;
  border-top: none;
  border-bottom: none;
}

.sandwichEnd {
  border-left:$componentBorder;
  border-right: $componentBorder;
  border-top: none;
  border-bottom: $componentBorder;
  position:sticky;
  bottom:0;
}

#next{
  width:20%;
  float:right;
}

#prev {
  width:20%;
  float:left;
}

#closeInfo {
  ::after {
    content: "CLOSE";
}
}


// .appearanceRow:nth-child(2n) {
//   background-color: $body;
// }
// .appearanceRow:nth-child(2n-1) {
//   background-color: lighten(desaturate($color: $header, $amount: 50%), $amount: 40%);
// }

// .appearanceTable {
// 	padding-left: 0;
//   margin-top: 5px;
// }

// .appearanceRow {
// 	color: black;
//   font-weight:normal;
// 	padding: 5px;
// 	list-style-type: none;
// 	margin-left: 0;
// }